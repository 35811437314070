@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #000000 !important;
}

h6 {
  color: #D6C4B9;
  font-family: "Inter";
}

h1 {
  color: #EB6E48;
  font-family: "Inter";
}

h2 {
  color: white;
  font-family: "Inter";
}

p {
  color: white;
  font-family: "Courier Prime";
}

.SteamButton {
  background-color: white;
  display: inline-block;
  color: black;
  font-family: "Inter";
}

.AboutUsTitle {
  color: white;
  font-family: "Courier Prime";
}


.gameText {
  max-width: 18em;
}

.aboutus-paragraph {
  max-width: 36em;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
